import { graphql, PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import { ContentWrapper, RichTextField } from "../components/text-content-wrapper";
import { JobsSearchTile, TileNavigation } from "../components/tile-navigation";
import { JobOffersCountInterface } from "../graphql/jobs";
import { StandardPageContentInterface } from "../graphql/standard-page";

interface DisclaimerPageDataInterface {
  pimcore: {
    content: StandardPageContentInterface;
    jobOffersCount: JobOffersCountInterface;
  }
}

const DisclaimerPage: React.FC<PageProps<DisclaimerPageDataInterface>> = ({ data }) => (
  <Layout
    title={data.pimcore.content.metaTitle}
    description={data.pimcore.content.metaDescription}
    canonical={data.pimcore.content.metaCanonical}
    openGraph={{ title: data.pimcore.content.metaOgTitle, description: data.pimcore.content.metaOgTitle, image: data.pimcore.content.metaOgImage }}
    schemaOrg={data.pimcore.content.metaSchema}
  >
    <Main>
      <MainWrapper>
        <ContentWrapper withXBackground>
          <Content>
            <Headline>{data.pimcore.content.title}</Headline>
            <RichTextField dangerouslySetInnerHTML={{ __html: data.pimcore.content.content }} />
          </Content>
        </ContentWrapper>
      </MainWrapper>
      <TileNavigation top={"team"} bottom={"office"}>
        <JobsSearchTile jobsCount={data.pimcore.jobOffersCount.totalCount} />
      </TileNavigation>
    </Main>
  </Layout>
);

const Main = styled.main.attrs({ className: "pt-20 tablet:pt-32" })``;

const MainWrapper = styled.div.attrs({ className: "pb-28 tablet:px-40 tablet:pb-36" })``;

const Content = styled.div.attrs({ className: "px-5 tablet:px-28 relative z-10 text-gray" })``;

const Headline = styled.h1.attrs({ className: "my-8 text-center font-light text-subHeadlineMobile tablet:my-16 tablet:text-modalHeadline" })``;

export const query = graphql`
  query($fullpath: String = "/Pages/Home/Disclaimer") {
    pimcore {
      ...jobOffersCount
      ...standardPageData
    }
  }
`;

export default DisclaimerPage;